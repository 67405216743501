
import Cocoa1 from "./assets/c5.jpg"
import Cocoa2 from "./assets/c4.jpg"
import Cocoa3 from "./assets/prodImage.jpg"
import Cocoa4 from "./assets/cocoa4.jpg"



export const slider = [
    {
        id: "1",
        image: Cocoa2,

    },
    {
        id: "2",
        image: Cocoa3,
    },
    {
        id: "3",
        image: Cocoa4,
    },
    {
        id: "4",
        image: Cocoa1,
    },
]